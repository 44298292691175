import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'
import { Link } from 'gatsby'
import { preloadsState } from '@/store'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import { TEMPLATE_TYPES } from '@/constants'
import { HeaderGameplay } from '@/blocks/HeaderGameplay'
import { useImageAssets } from '@/hooks/useImageAssets'

const NotFoundPage = ({ location }) => {
  const story = useStoryblok(null, location)
  const setPreloads = useSetRecoilState(preloadsState)
  const { headerGameplay } = useImageAssets()

  useEffect(() => {
    setPreloads({ pathname: '404', images: { headerGameplay } })
  }, [setPreloads, headerGameplay])

  // preview unpublished content
  if (story && story.content) {
    const Template = TEMPLATE_TYPES[story.content.component]

    return Template ? (
      <Template story={story} />
    ) : (
      <div>Unpublished preview is not supported for this content type.</div>
    )
  }

  return (
    <HeaderGameplay
      heading="Page Not found"
      image={headerGameplay}
      introText={
        <p>
          If you entered a web address please check it was correct, or{' '}
          <Link to="/">click here</Link> to go to the homepage.
        </p>
      }
    />
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
